const removeUselessCharacters = (string) => string.split('').map((char, index) => {
  if (char === '-' && string[index - 1] === '-') {
    return '';
  }
  return char;
}).join('');

const formatTitle = (title) => {
  let formattedTitle = title.replace(/[^a-zA-Z0-9_]/g, '-');
  formattedTitle = formattedTitle.replace(/\s/g, '-');
  formattedTitle = removeUselessCharacters(formattedTitle);
  return formattedTitle?.toLowerCase();
};

const getConseilUrl = ({ id, title }) => `/conseils/${id}-${formatTitle(title)}`;

const getJobOfferUrl = ({ domain, id, title }) => `/offres-d-emploi/${domain}/${id}-${formatTitle(title)}`;

const getAgenceUrl = ({ id, title }) => `/agences/${id}-${formatTitle(title)}`;

const getPrestationUrl = ({ id, title, agencyId }) => `/prestations/${agencyId}/${id}-${formatTitle(title)}`;

export default {
  getConseilUrl,
  getJobOfferUrl,
  getAgenceUrl,
  getPrestationUrl,
};
