import client from './client';

const initializePayment = ({ agencyId, invoiceNumber, form }) => client.post(
  `/free-invoice-payments/${agencyId}/${invoiceNumber}:initialize-payment`, form,
);

const getPaymentResult = ({ id }) => client.get(`/free-invoice-payments/${id}/payment-status`);

export default {
  initializePayment,
  getPaymentResult,
};
