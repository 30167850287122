import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import {
  ButtonComponent,
  InputComponent,
  LoaderComponent,
  ModalTemplate,
} from '@zolteam/axenergie-ui-library';
import { useMutation, useQuery } from 'react-query';
import ReCAPTCHA from 'react-google-recaptcha';

// Constants
import strings from '../../constants/Strings';
import colors from '../../constants/Colors';
// Hooks and service
import useIsMobile from '../../hooks/useIsMobile';
import PaymentService from '../../services/api/PaymentService';
// Utils
import { isCorrectEmail, validateNotEmptyField } from '../../utils/validators';

const PaymentModal = ({
  closeModal,
  agencyId,
  logo,
  openingHours,
  agencyPhone,
  agencyName,
  returnUrl,
  isSuccess,
  invoiceId,
  isFailure,
  businessName,
  legalOfficer,
  sirenNumber,
}) => {
  const router = useRouter();
  const [showNumber, setShowNumber] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Form related states
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceNumberError, setInvoiceNumberError] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [firstnameError, setFirstnameError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState(false);
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState(false);
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState(false);

  const recaptchaRef = useRef();

  const [isMobile] = useIsMobile();

  // Queries
  const postDemand = useMutation('postDemand', (form) => PaymentService.initializePayment({
    agencyId,
    invoiceNumber: form.invoiceNumber,
    form,
  }));

  const getPaymentResultQuery = useQuery(['getPaymentResultQuery', isSuccess, invoiceId],
    () => PaymentService.getPaymentResult({ id: invoiceId }), {
      enabled: isSuccess && !!invoiceId,
    });

  const getErrorPaymentMessage = (error) => {
    switch (error.code) {
      case 'amount_too_low': {
        return strings.errors.amountToLow;
      }
      case 'amount_too_high': {
        return strings.errors.amountToHigh;
      }
      case 'invoice_number_too_long': {
        return strings.errors.invoiceNumberTooLong;
      }
      case 'invalid_captcha': {
        return strings.errors.invalidCaptcha;
      }
      case 'validation_failed': {
        return `${Object.keys(error.errors || {})?.[0]} : ${Object.values(error.errors || {})?.[0]?.join(' ')}`;
      }
      default: {
        return strings.errors.payment;
      }
    }
  };

  useEffect(() => {
    if (postDemand.isError) {
      const formError = Object.values(postDemand?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      setErrorMessage(formError);
      setFeedbackMessage(getErrorPaymentMessage(postDemand?.error?.response?.data));
    }
    if (postDemand.isSuccess) {
      window.location.href = postDemand.data?.data?.url;
    }
  }, [postDemand.data?.data?.url, postDemand?.error?.response?.data, postDemand.isError, postDemand.isSuccess]);

  useEffect(() => {
    if (isFailure) {
      setFeedbackMessage(strings.errors.payment);
    }
    if (getPaymentResultQuery?.data?.data?.is_paid !== undefined) {
      if (getPaymentResultQuery?.data?.data?.is_paid) {
        setFeedbackMessage(strings.success.payment);
      } else {
        setFeedbackMessage(strings.errors.payment);
      }
    } else {
      setFeedbackMessage(null);
    }
  }, [getPaymentResultQuery?.data?.data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if ((!agencyId)
        || invoiceNumberError
          || nameError
          || firstnameError
          || emailError
          || addressError
          || zipcodeError
          || cityError
          || amountError
    ) {
      return false;
    }
    return recaptchaRef?.current?.execute();
  };

  const getSuccessUrl = () => {
    if (returnUrl.includes('?')) {
      return `${returnUrl}&success=true`;
    }
    return `${returnUrl}?success=true`;
  };

  const getFailureUrl = () => {
    if (returnUrl.includes('?')) {
      return `${returnUrl}&failure=true`;
    }
    return `${returnUrl}?failure=true`;
  };

  const onReCAPTCHAChange = async (code) => {
    if (!code) {
      return false;
    }
    await postDemand.mutate({
      firstName: firstname,
      lastName: name,
      emailAddress: email,
      address,
      postalCode: zipcode,
      city,
      amount: Math.round(amount * 100),
      invoiceNumber,
      paymentReturnUrl: getSuccessUrl(),
      paymentCancelUrl: getFailureUrl(),
      captchaResponse: code,
    });
    return recaptchaRef?.current?.reset();
  };

  const renderModalTitle = () => (
    <div
      className="form-file-text-area-width"
      style={{ marginBottom: -50 }}
    >
      <p className="h1 medium-weight white-text big-line-height">
        {router.query?.quoteSigned
          ? strings.afterQuote
          : `${strings.payBillTo} ${agencyName ? strings.to : ''} ${agencyName || ''}`}
      </p>
    </div>
  );

  return (
    <ModalTemplate
      closeModal={closeModal}
      title={renderModalTitle()}
    >
      { feedbackMessage
        ? (
          <div className={`p-3 ${isMobile ? 'ph-1' : 'ph-6'}`}>
            <p className="medium-weight h5 grey-800-text">{feedbackMessage}</p>
          </div>
        )
        : (
          <div
            className={`d-flex f-column align-center p-3 ${isMobile ? '' : 'ph-6'}`}
            style={{ width: isMobile ? 'calc(100vw - 2rem)' : 1000 }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
              onChange={onReCAPTCHAChange}
            />
            {/* Agency info line */}
            {agencyId ? (
              <div
                className={`d-flex f-row f-wrap 
                ${isMobile ? 'justify-center' : 'justify-between'} align-center full-width`}
              >
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={`
                ${process.env.NEXT_PUBLIC_IMAGE_PREFIX_URL}/public/${logo}`}
                  alt="agency-logo"
                  height={80}
                  className="mv-4"
                />

                <div className="d-flex f-column mb-3">
                  <span className="grey-800-text h6 normal-weight">
                    {strings.openingHours}
                  </span>
                  <div
                    className="normal-weight medium-text grey-400-text mh-2 mv-2 wysiwyg-text"
                    style={{ maxWidth: 250 }}
                    dangerouslySetInnerHTML={{ __html: openingHours }}
                  />
                </div>
                <div>
                  <ButtonComponent onClick={() => setShowNumber(true)} theme="outline">
                    <div className="mv-2">
                      {showNumber ? agencyPhone : strings.seeNumber}
                    </div>
                  </ButtonComponent>
                </div>
              </div>
            )
              : null}
            <div className="line grey-400-background opacity-01 mv-4 full-width" />
            {
              errorMessage
                ? <p className="h5 primary-red-text ml-4">{errorMessage}</p>
                : null
            }
            <p className="grey-800-text ml-5 full-width">{strings.allFieldsAreRequired}</p>
            {/* form */}
            <div
              className="d-flex f-row f-wrap justify-center mt-3"
            >
              <div className="form-file-text-area-width m-2">
                <InputComponent
                  onChange={setInvoiceNumber}
                  id="bill-number"
                  value={invoiceNumber}
                  label={strings.billNumber}
                  isError={invoiceNumberError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setInvoiceNumberError, invoiceNumber)}
                />
              </div>
              <div className="form-file-text-area-width m-2 name-on-bill-input">
                <InputComponent
                  onChange={setName}
                  id="client-name"
                  value={name}
                  label={`${strings.nameOnBill}\t`}
                  isError={nameError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setNameError, name)}
                />
              </div>
              <div className="form-file-text-area-width m-2">
                <InputComponent
                  onChange={setFirstname}
                  id="client-firstname"
                  value={firstname}
                  label={strings.firstname}
                  isError={firstnameError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setFirstnameError, firstname)}
                />
              </div>
              <div className="form-file-text-area-width m-2">
                <InputComponent
                  onChange={setAddress}
                  id="client-address"
                  value={address}
                  label={strings.address}
                  isError={addressError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setAddressError, address)}
                />
              </div>
              <div className="form-input-width m-2">
                <InputComponent
                  onChange={setZipcode}
                  id="client-zipcode"
                  value={zipcode}
                  label={strings.zipcode}
                  isError={zipcodeError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setZipcodeError, zipcode)}
                />
              </div>
              <div className="form-input-width m-2">
                <InputComponent
                  onChange={setCity}
                  id="client-city"
                  value={city}
                  label={strings.city}
                  isError={cityError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setCityError, city)}
                />
              </div>
              <div className="form-input-width m-2">
                <InputComponent
                  onChange={setEmail}
                  id="user-mail"
                  value={email}
                  label={strings.email}
                  isError={emailError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => setEmailError(!isCorrectEmail(email))}
                />
              </div>
              <div className="form-input-width m-2 relative">
                <InputComponent
                  type="number"
                  onChange={(value) => {
                    if ((!Number.isNaN(value) && parseInt(value, 10) > 0)) {
                      setAmount(value);
                    } else {
                      setAmount('');
                    }
                  }}
                  placeholder="00,00"
                  id="bill-amount"
                  value={amount}
                  label={strings.amountToPay}
                  isError={amountError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setAmountError, amount)}
                />
              </div>

            </div>
            <p className="grey-800-text ml-5 full-width">
              { businessName
                ? <span>{`${strings.paymentTo} ${businessName} - `}</span> : null}
              { sirenNumber
                ? <span>{` SIREN : ${sirenNumber}.`}</span> : null}
              { legalOfficer
                ? <span>{` ${strings.representBy} ${legalOfficer}`}</span> : null}
            </p>
            <div>
              {
            (!agencyId)
            || !firstname
            || !amount
            || !name
            || !email
            || !address
            || !zipcode
            || !city
            || !invoiceNumber ? null
              : (
                <div className="ml-4 mv-4">
                  <ButtonComponent onClick={handleSubmit}>
                    <div className="m-2 ph-5">
                      {
                          postDemand?.isLoading ? <LoaderComponent size={30} borderWidth={3} color={colors.white} />
                            : <span className="uppercase">{strings.pay}</span>
                        }
                    </div>
                  </ButtonComponent>
                </div>
              )
          }
            </div>
          </div>
        )}
    </ModalTemplate>
  );
};

PaymentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  agencyId: PropTypes.number,
  logo: PropTypes.string,
  openingHours: PropTypes.string,
  agencyPhone: PropTypes.string,
  returnUrl: PropTypes.string.isRequired,
  agencyName: PropTypes.string,
  isSuccess: PropTypes.bool,
  isFailure: PropTypes.bool,
  businessName: PropTypes.string,
  legalOfficer: PropTypes.string,
  sirenNumber: PropTypes.string,
  invoiceId: PropTypes.string,
};

PaymentModal.defaultProps = {
  agencyId: null,
  logo: null,
  openingHours: null,
  agencyPhone: null,
  agencyName: null,
  isSuccess: false,
  isFailure: false,
  businessName: null,
  legalOfficer: null,
  sirenNumber: null,
  invoiceId: null,
};

export default PaymentModal;
